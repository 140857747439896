// © 2019 Yogesh Kotadiya
// https://github.com/yogeshkotadiya

/**
 * @function highlightLine
 * @param {string} str - Highlight lines prop
 * @returns {number[]} Array of numbers
 */
function highlightLine(str: string) {
  const arr = str.split(",").map(i => {
    if (typeof parseInt(i, 10) === "number" && i.length === 1) {
      return parseInt(i, 10);
    }
    const codeStr = i.split("-").map(e => parseInt(e, 10));
    const num = [codeStr[0]];
    while (codeStr[0] < codeStr[1]) {
      codeStr[0]++;
      num.push(codeStr[0]);
    }
    return num;
  });
  return [...arr]; // Flattens the array
}

export default highlightLine;
