import * as React from "react";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
// @ts-ignore
import { MDXRenderer } from "gatsby-plugin-mdx";
// @ts-ignore
import { MDXProvider } from "@mdx-js/react";
// @ts-ignore
import { preToCodeBlock } from "mdx-utils";

import { rhythm, scale } from "@Utils/typography";
import Code from "@Components/mdxComponents/Code";
import PageHead from "@Components/PageHead";

import "./style/_blogPost.scss";

function BlogPostTemplate(props: any) {
  const { author } = props.data;
  const { frontmatter, body, fields } = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;

  const components = {
    pre: (preProps: any) => {
      const newProps = preToCodeBlock(preProps);
      // if there's a codeString and some props, we passed the test
      if (newProps) {
        return <Code {...newProps} />;
      }
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />;
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {frontmatter.title} |{siteTitle}
        </title>
      </Helmet>
      <PageHead backgroundImg={frontmatter.banner.childImageSharp.fluid}>
        <h2
          className="head-title"
          style={{
            ...scale(1.2),
            fontSize: rhythm(3.5),
            color: "#00e0d0",
            lineHeight: "8rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {frontmatter.title}
        </h2>
      </PageHead>
      <div
        style={{
          ...scale(0.6),
        }}
      >
        <div
          className="author"
          style={{
            marginBottom: rhythm(5),
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: rhythm(60),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            marginTop: rhythm(2),
            ...scale(3 / 5),
          }}
        >
          <Img className="author__image" fluid={author.childImageSharp.fluid} />
          <div>
            <p className="author__name">{fields.author}</p>
            <p className="author__date">{frontmatter.date}</p>
          </div>
        </div>
        <div
          className="blog__body"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: rhythm(45),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            ...scale(0.6),
          }}
        >
          <MDXProvider components={components}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!, $authorProfileImage: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
        author
      }
      frontmatter {
        title
        date
        readLength
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        bannerCredit
      }
      body
    }
    author: file(relativePath: { eq: $authorProfileImage }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
