import * as React from "react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

import highlightLine from "./HighlightLine";

export interface CodeProps {
  codeString: string;
  language: Language;
  highlight: string;
}

const Code = ({ codeString, language, highlight }: CodeProps) => {
  const highlightLines =
    highlight === undefined ? [] : highlightLine(highlight);

  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={codeString}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={style}>
          <code className={className.split(" ")[1]}>
            {tokens.map((line, i) => (
              <div
                key={i}
                {...getLineProps({ line, key: i })}
                className={
                  highlightLines.includes(i + 1)
                    ? "gatsby-highlight-code-line"
                    : "code-line"
                }
              >
                <span
                  style={{
                    display: " inline-block",
                    width: "2em",
                    userSelect: "none",
                    opacity: 0.3,
                  }}
                >
                  {i + 1}
                </span>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </code>
        </pre>
      )}
    </Highlight>
  );
};

export default Code;
